<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <a href="#press" id="press-link">
      IN THE PRESS
      <br>
      ↓
    </a>
    <h2 class="text-center page-heading">ABOUT</h2>
    <div class="container">
      <div class="row">
        <div class="col">
          <h4 style="margin-bottom: 16px;">Mane Street Market = Marketplace for sales horses and equine services.</h4>
          <p style="line-height: 1.8;">Mane Street Market is a marketing platform for equestrians to market their sales horses and showcase their equine services.  Our goal is to streamline the search process for sales horses and equine services through our website <a href="https://manestreetmarket.com/">www.manestreetmarket.com</a> and the free app on Apple and Google Play.</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5 style="margin: 48px 0 36px;">FOUNDER/CEO</h5>
          <div class="card-deck mb-3">
            <div class="card mb-4 box-shadow person-card">
              <div class="card-body team-card">
                <img class="img-headshot" src="../assets/img/india_headshot.jpg" />
                <h2 class="card-title pricing-card-title">India Wilkinson</h2>
                <div class="row position-title-row">
                  <span>CEO</span><a class="email-link" href="mailto:support@manestreetauctions.com"><img src="../assets/img/icon_email.png" /></a>
                </div>
                <h6>Atlanta, Georgia and Vero Beach, Florida</h6>
                <p id="india-bio" class="clamped">India is a lifelong horse owner/competitor and has decades of experience in the marketing/sales industry with the last 15 years specifically in the equestrian marketing arena. India offers her expertise in helping sellers market their sales horses and buyers find their new equine partner through Mane Street Market. Her vision is to match up buyers and sellers on a premium online platform. India can be reached at <a href="mailto:support@manestreetmarket.com">support@manestreetmarket.com</a>.</p>       
                <button class="read-more-button" id="india-bio-button" @click="() => removeClamped('india-bio')">Read more</button>
              </div>
            </div>
            <!-- <div class="card mb-4 box-shadow">
              <div class="card-body team-card">
                <img class="img-headshot" src="../assets/img/caitlin_headshot.jpg" />
                <h2 class="card-title pricing-card-title">Caitlin Haggerty</h2>
                <div class="row position-title-row">
                  <span>Vice President of Sales</span><a class="email-link" href="mailto:support@manestreetauctions.com"><img src="../assets/img/icon_email.png" /></a>
                </div>
                <h6>Lafayette, New Jersey</h6>
                <p id="caitlin-bio" class="clamped">Caitlin's  love of horses has been the foundation of everything she has accomplished. Over the last 12 years Caitlin has built a successful real estate business focusing on equestrian properties. She is also the owner and operator of her own equestrian facility in New Jersey, starting and training horses to be well rounded, solid partners in both English and Western disciplines. With decades of marketing and selling experience in a fast paced, competitive market she has the knowledge and ability to bring buyers and sellers together successfully through the Mane Street Auction platform.  Caitlin is always happy to help and can be reached at <a href="mailto:support@manestreetauctions.com">support@manestreetauctions.com</a></p>       
                <button class="read-more-button" id="caitlin-bio-button" @click="() => removeClamped('caitlin-bio')">Read more</button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row" id="press">
        <div class="col">
          <h5 style="margin: 48px 0 36px;">IN THE PRESS</h5>
          <div class="card-deck vertical-deck mb-3">
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://www.horseradionetwork.com/2024/06/girls-only-episode-with-india-wilkinson-of-mane-street-market-and-realli-bad-adz-for-june-7th-2024-by-kentucky-performance-products/">
                    <img src="../assets/img/press-17.jpg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://www.horseradionetwork.com/2024/06/girls-only-episode-with-india-wilkinson-of-mane-street-market-and-realli-bad-adz-for-june-7th-2024-by-kentucky-performance-products/">Horses in the Morning Podcast</a>
                  <a target="_blank" href="https://www.horseradionetwork.com/2024/06/girls-only-episode-with-india-wilkinson-of-mane-street-market-and-realli-bad-adz-for-june-7th-2024-by-kentucky-performance-products/">LISTEN TO PODCAST →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://www.stormlilymarketing.com/post/idea-reality-build-horse-business-india-wilkinson">
                    <img src="../assets/img/press-16.jpg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://www.stormlilymarketing.com/post/idea-reality-build-horse-business-india-wilkinson">How to Market Your Horse Business Podcast</a>
                  <a target="_blank" href="https://www.stormlilymarketing.com/post/idea-reality-build-horse-business-india-wilkinson">LISTEN TO PODCAST →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://issuu.com/wecmag/docs/wec_magazine_vol_iv_2023/102?fbclid=IwAR03RQwWPnduKCeoC-C_emsTrQiJe0qNlgR1vu8OrCnwPrhmXoLmDYidf_E">
                    <img src="../assets/img/press-15.jpg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://issuu.com/wecmag/docs/wec_magazine_vol_iv_2023/102?fbclid=IwAR03RQwWPnduKCeoC-C_emsTrQiJe0qNlgR1vu8OrCnwPrhmXoLmDYidf_E">World Equestrian Center Magazine Volume IV 2023</a>
                  <span class="press-info-body">Transforming the Buying and Selling Process</span>
                  <a target="_blank" href="https://issuu.com/wecmag/docs/wec_magazine_vol_iv_2023/102?fbclid=IwAR03RQwWPnduKCeoC-C_emsTrQiJe0qNlgR1vu8OrCnwPrhmXoLmDYidf_E">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://voyageatl.com/interview/exploring-life-business-with-india-wilkinson-of-mane-street-market/">
                    <img src="../assets/img/press-14.jpg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://voyageatl.com/interview/exploring-life-business-with-india-wilkinson-of-mane-street-market/">Voyage ATL: Exploring Life & Business with India Wilkinson of Mane Street Market</a>
                  <a target="_blank" href="https://voyageatl.com/interview/exploring-life-business-with-india-wilkinson-of-mane-street-market/">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://publuu.com/flip-book/237655/570482/page/6">
                    <img src="../assets/img/press-13.jpg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://publuu.com/flip-book/237655/570482/page/6">Creating an Effective Sales Horse ad in Today's Market</a>
                  <a target="_blank" href="https://publuu.com/flip-book/237655/570482/page/6">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://www.majorleagueeventing.com/podcast/episode/797fe8a5/the-dos-and-donts-when-posting-equine-sales-ads-with-india-wilkinson">
                    <img src="../assets/img/press-12.png" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://www.majorleagueeventing.com/podcast/episode/797fe8a5/the-dos-and-donts-when-posting-equine-sales-ads-with-india-wilkinson">Big League Eventing podcast</a>
                  <a target="_blank" href="https://www.majorleagueeventing.com/podcast/episode/797fe8a5/the-dos-and-donts-when-posting-equine-sales-ads-with-india-wilkinson">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://www.thetechequestrian.com/home/2022/12/12/top-ten-blog-posts-of-2022">
                    <img src="../assets/img/press-01.jpg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://www.thetechequestrian.com/home/2022/12/12/top-ten-blog-posts-of-2022">The Tech Equestrian's Top 10 blogs for 2022 - Mane Street Market is #7!</a>
                  <a target="_blank" href="https://www.thetechequestrian.com/home/2022/12/12/top-ten-blog-posts-of-2022">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://midsouthhorsereview.com/trends-and-tips-for-buying-and-selling-horses-in-2023">
                    <img src="../assets/img/press-02.jpg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://midsouthhorsereview.com/trends-and-tips-for-buying-and-selling-horses-in-2023">MSM Creator India Wilkinson shares the trends for horse sales in 2023</a>
                  <a target="_blank" href="https://midsouthhorsereview.com/trends-and-tips-for-buying-and-selling-horses-in-2023">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://anchor.fm/horse-industry-podcast/episodes/India-Wilkinson-Mane-Street-Market-BuyingSelling-Horses-e1p47a6/a-a8m0uln">
                    <img src="../assets/img/press-03.jpg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://anchor.fm/horse-industry-podcast/episodes/India-Wilkinson-Mane-Street-Market-BuyingSelling-Horses-e1p47a6/a-a8m0uln">India Wilkinson shares her insight to the trends in horse sales</a>
                  <span class="press-info-body">Horse Industry Podcast</span>
                  <a target="_blank" href="https://anchor.fm/horse-industry-podcast/episodes/India-Wilkinson-Mane-Street-Market-BuyingSelling-Horses-e1p47a6/a-a8m0uln">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://www.majorleagueeventing.com/podcast/episode/4ddee050/bonus-introducing-mane-street-market">
                    <img src="../assets/img/press-04.jpeg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://www.majorleagueeventing.com/podcast/episode/4ddee050/bonus-introducing-mane-street-market">Major League Eventing</a>
                  <span class="press-info-body">Bonus: Introducing Mane Street Market</span>
                  <a target="_blank" href="https://www.majorleagueeventing.com/podcast/episode/4ddee050/bonus-introducing-mane-street-market">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://issuu.com/wynnwood/docs/sept_oct_22/90">
                    <img src="../assets/img/press-05.jpeg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://issuu.com/wynnwood/docs/sept_oct_22/90">Equestrian Living</a>
                  <span class="press-info-body">Mane Street Market App: Changing the Digital Buying/Selling Experience</span>
                  <a target="_blank" href="https://issuu.com/wynnwood/docs/sept_oct_22/90">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://theleadlinepodcast.com/ep-31-developing-a-mobile-app-for-your-horse-business-with-india-wilkinson/">
                    <img src="../assets/img/press-06.jpeg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://theleadlinepodcast.com/ep-31-developing-a-mobile-app-for-your-horse-business-with-india-wilkinson/">The Lead Line</a>
                  <span class="press-info-body">Ep 31 | Developing a Mobile App for Your Horse Business with India Wilkinson</span>
                  <a target="_blank" href="https://theleadlinepodcast.com/ep-31-developing-a-mobile-app-for-your-horse-business-with-india-wilkinson/">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://wiredmustang.com/mustang-spotlight-mane-street-market-app-india-wilkinson-owner/">
                    <img src="../assets/img/press-07.jpeg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://wiredmustang.com/mustang-spotlight-mane-street-market-app-india-wilkinson-owner/">MUSTANG SPOTLIGHT: MANE STREET MARKET APP, INDIA WILKINSON, OWNER</a>
                  <a target="_blank" href="https://wiredmustang.com/mustang-spotlight-mane-street-market-app-india-wilkinson-owner/">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://www.thetechequestrian.com/home/2022/5/1/mane-street-market-app-changing-the-digital-buyingselling-horse-experience">
                    <img src="../assets/img/press-08.jpeg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://www.thetechequestrian.com/home/2022/5/1/mane-street-market-app-changing-the-digital-buyingselling-horse-experience">MANE STREET MARKET APP: Changing the Digital Buying/Selling Experience</a>
                  <a target="_blank" href="https://www.thetechequestrian.com/home/2022/5/1/mane-street-market-app-changing-the-digital-buyingselling-horse-experience">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://sidelinesmagazine.com/sidelines-feature/creating-an-app-for-the-equine-world.html">
                    <img src="../assets/img/press-09.jpeg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://sidelinesmagazine.com/sidelines-feature/creating-an-app-for-the-equine-world.html">Creating an app for the Equine world</a>
                  <a target="_blank" href="https://sidelinesmagazine.com/sidelines-feature/creating-an-app-for-the-equine-world.html">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://www.stormlilymarketing.com/post/5-tips-maximize-your-event-rider-sponsorship-with-india-wilkinson">
                    <img src="../assets/img/press-10.jpeg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://www.stormlilymarketing.com/post/5-tips-maximize-your-event-rider-sponsorship-with-india-wilkinson">5 tips to maximize your event or rider sponsorship</a>
                  <a target="_blank" href="https://www.stormlilymarketing.com/post/5-tips-maximize-your-event-rider-sponsorship-with-india-wilkinson">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
            <div class="card mb-4 box-shadow">
              <div class="card-body press-card">
                <div class="img-container">
                  <a target="_blank" href="https://www.stormlilymarketing.com/post/how-increase-sponsorship-revenue-with-india-wilkinson">
                    <img src="../assets/img/press-11.jpeg" />
                  </a>
                </div>
                <div class="press-info-container">
                  <a class="press-info-title" target="_blank" href="https://www.stormlilymarketing.com/post/how-increase-sponsorship-revenue-with-india-wilkinson">How to increase your sponsorship revenue</a>
                  <a target="_blank" href="https://www.stormlilymarketing.com/post/how-increase-sponsorship-revenue-with-india-wilkinson">VIEW ARTICLE →</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<style lang="scss">
.page-heading {
  margin: -40px 0 48px;
  padding: 32px 0 40px;
  background-color: #141645;
  color: white;
}

#press-link {
  position: fixed;
  margin: auto;
  bottom: 32px;
  left: 0;
  right: 0;
  width: 100px;
  z-index: 999;
  background-color: #141645;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 12px 24px;
  white-space: nowrap;
  display: inline-table;
  font-weight: bold;

  &:hover {
    opacity: 0.9;
  }
}

.card-deck.vertical-deck {
  flex-direction: column;
}

.person-card {
  max-width: 50%;
}

.team-card {
  .img-headshot {
    width: calc(100% + 2.5rem);
    height: 400px;
    object-fit: cover;
    object-position: top;
    margin: -1.25rem -1.25rem 1.25rem;
  }

  .position-title-row {
    margin-left: 0;
    margin-bottom: 8px;
  }

  .email-link {
    padding: 4px;
    display: inline-flex;
    border: 1px solid lightgray;
    border-radius: 50%;
    margin-left: 8px;
    img {
      opacity: 0.4;
      width: 16px;
      height: 16px;
    }
  }

  .clamped {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .read-more-button {
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    color: #0A1369;
    text-decoration: underline;
    display: inline;
  }
}

.press-card {
  display: flex;

  .img-container {
    min-width: 30%;
    max-width: 400px;
    margin: -1.25rem 2.5rem -1.25rem -1.25rem;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .press-info-container {
    .press-info-title {
      font-size: 1.5rem;
      font-weight: 500;
      color: black;
      line-height: 1.5;
    }

    .press-info-body {
      display: block;
      margin: 16px 0;
    }

    a:not(.press-info-title) {
      display: block;
      color: #0A1369;
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: 480px) {
  .page-heading {
    margin-bottom: 24px;
  }

  .person-card {
    max-width: 100%;
  }

  .press-card {
    flex-direction: column;
    padding: 0;

    .img-container {
      max-width: none;
      margin: 0;
    }

    .press-info-container {
      margin: 1.25rem;
    }
  }
}
</style>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from "@/components/SubHeader.vue";

export default {
  components: {BaseLayout, SubHeader},
  mounted() {
    document.title = 'About - Mane Street Market';
    window.scrollTo(0, 0);
    document.addEventListener('scroll', e => {
      const scroll = window.scrollY;
      if (scroll > 500) {
        document.querySelector('#press-link').style.display = 'none';
      } else {
        document.querySelector('#press-link').style.display = 'inline-table';
      }
    });
  },
  methods: {
    removeClamped(id) {
      const p = document.querySelector(`#${id}`);
      const btn = document.querySelector(`#${id}-button`);
      p.classList.toggle('clamped');
      if (p.classList.contains('clamped')) {
        btn.textContent = 'Read more';
      } else {
        btn.textContent = 'Read less';
      }
    }
  }
}
</script>